import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsCityModalClose } from "../redux/reducer/slices/commonSlice";
import { setIsAuthModalClose, setIsAuthQuestionModalClose } from '../redux/reducer/slices/profileSlice';
import { setIsAddressModalClose } from "../redux/reducer/slices/companiesSlice";
import { GlobalStyle, Wrapper, Content } from './styles';
// import { YMInitializer } from 'react-yandex-metrika';
import Header from "../containers/header/Header";
import Footer from "../containers/footer/Footer";
import FloatingSocialButtons from "../components/FloatingSocialButtons";
import { Message } from "../components/Message";
import Modal from "../components/Modal";
import AuthForm from "../containers/forms/authForm/AuthForm";
import SelectCityForm from "../containers/forms/selectCityForm/SelectCityForm";
import AddressSelectorForm from "../containers/forms/addressSelectorForm/AddressSelectorForm";
import AuthQuestionForm from "../containers/forms/authForm/AuthQuestionForm";
import { DraggableBox } from "../components/DraggableBox";
import { setGeocodeAddress, setGeocodeCoords } from "../redux/reducer/slices/mapSlice";
import styled from "styled-components";

const Layout = ({ children }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isDev = searchParams.get('dev') === 'true';
    const dispatch = useDispatch();
    const { device, isCityModalOpen } = useSelector((state) => state.common);
    const { isAuthModalOpen, isAuthQuestionModalOpen } = useSelector((state) => state.profile);
	const { isAddressModalOpen, saveCompanyStatus } = useSelector((state) => state.companies);

    useEffect(() => {
        if (saveCompanyStatus === 'fulfilled') {
            dispatch(setIsAddressModalClose());
        }
    }, [saveCompanyStatus]);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location.pathname]);
    
    return (
        <Wrapper>
			<GlobalStyle/>
			{/* <YMInitializer
                accounts={[50456719]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                }}
                version="2"
            /> */}

            {location.pathname !== '/app' && <Header/>}

            <Content style={{paddingTop: location.pathname === '/app' && '0'}}>
			    {children}
            </Content>

            {(location.pathname !== '/app' && device === 'desktop') || (location.pathname === '/' && device === 'mobile') ? <Footer/> : null}

            <Modal isOpen={isAuthModalOpen} onClose={() => {dispatch(setIsAuthModalClose())}}><AuthForm/></Modal>
            <Modal isOpen={isAddressModalOpen} onClose={() => {dispatch(setIsAddressModalClose()); dispatch(setGeocodeAddress(null)); dispatch(setGeocodeCoords(null));}}><AddressSelectorForm/></Modal>
            <Modal isOpen={isCityModalOpen} onClose={() => {dispatch(setIsCityModalClose())}}><SelectCityForm/></Modal>
            <Modal isOpen={isAuthQuestionModalOpen} onClose={() => {dispatch(setIsAuthQuestionModalClose())}}><AuthQuestionForm/></Modal>

            <Message/>

            <FloatingSocialButtonsWrapper>
                <FloatingSocialButtons/>
            </FloatingSocialButtonsWrapper>

            {isDev && (
                <DraggableBox/>
            )}
        </Wrapper>
    );
};

export default Layout;

const FloatingSocialButtonsWrapper = styled.div`
    @media (max-width: 798px) {
        display: none;
    }
`