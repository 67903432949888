import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const GEOCODER_API_URL = 'https://geocode-maps.yandex.ru/1.x/';
const API_KEY = 'e119accb-b623-4fe2-9b13-6f8e128efb5f';

function getBoundingBoxFromPolygons(deliveryPolygons) {
    let minLongitude = Infinity;
    let maxLongitude = -Infinity;
    let minLatitude = Infinity;
    let maxLatitude = -Infinity;
  
    deliveryPolygons.forEach(polygon => {
      polygon.forEach(([longitude, latitude]) => {
        minLongitude = Math.min(minLongitude, longitude);
        maxLongitude = Math.max(maxLongitude, longitude);
        minLatitude = Math.min(minLatitude, latitude);
        maxLatitude = Math.max(maxLatitude, latitude);
      });
    });
  
    return `${minLatitude},${minLongitude}~${maxLatitude},${maxLongitude}`;
}

export const sendGeocodeAddress = createAsyncThunk('map/sendGeocodeAddress', async (address, { getState, rejectWithValue }) => {
    const state = getState();
    const currentCityId = state.common.currentCity.id || "18";
    const deliveryPolygons = state.common.deliveryZones.find(city => city.id === currentCityId).zones;
    const polygonsGeometry = deliveryPolygons.map((zone) => 
        zone.coords.map(coord => [coord.lat, coord.lon]),
    )

    try {
        const response = await axios.get(GEOCODER_API_URL, {
            params: {
                apikey: API_KEY,
                geocode: address,
                format: 'json',
                rspn: 1,
                bbox: `${getBoundingBoxFromPolygons(polygonsGeometry)}`,
            }
        });

        const { GeoObjectCollection } = response.data.response;
        const geoObjects = GeoObjectCollection.featureMember;

        if (!geoObjects.length) {
            throw new Error('Адрес не найден');
        }

        const results = geoObjects.map((item) => {
            const geoObject = item.GeoObject;
            const coords = geoObject.Point.pos.split(' ').map(Number).reverse();
            const name = geoObject.name//.metaDataProperty.GeocoderMetaData.text;
            const isHouse = geoObject.metaDataProperty.GeocoderMetaData.kind === 'house';

            const components = geoObject.metaDataProperty.GeocoderMetaData.Address.Components;
            const getComponent = (kind) => {
                const component = components.find((c) => c.kind === kind);
                return component ? component.name : null;
            };

            const addressObj = {
                fullName: geoObject.metaDataProperty.GeocoderMetaData.text,
                locality: getComponent('locality'),
                street: getComponent('street'),
                house: getComponent('house') ? parseInt(getComponent('house'), 10) : null,
                lng: coords[1],
                lat: coords[0],
            };

            return { coords, name, isHouse, addressObj: addressObj };
        });

        return results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendGeocodeCoords = createAsyncThunk('map/sendGeocodeCoords', async (coords, { rejectWithValue }) => {
    const reversCoords = coords.reverse();

    try {
        const response = await axios.get(GEOCODER_API_URL, {
            params: {
                apikey: API_KEY,
                geocode: reversCoords.join(','),
                format: 'json',
                kind: 'house',
                results: 1,
            },
        });
            const { GeoObjectCollection } = response.data.response;
            const firstGeoObject = GeoObjectCollection.featureMember.length > 0
                ? GeoObjectCollection.featureMember[0].GeoObject
                : undefined;

            if (!firstGeoObject) {
                throw new Error('Адрес не найден');
            }
            const coords = firstGeoObject.Point.pos.split(' ').map(Number).reverse();
            const name = firstGeoObject.name//.metaDataProperty.GeocoderMetaData.text;
            const isHouse = firstGeoObject.metaDataProperty.GeocoderMetaData.kind === 'house';

            const components = firstGeoObject.metaDataProperty.GeocoderMetaData.Address.Components;
            const getComponent = (kind) => {
                const component = components.find((c) => c.kind === kind);
                return component ? component.name : null;
            };

            const addressObj = {
                fullName: firstGeoObject.metaDataProperty.GeocoderMetaData.text,
                locality: getComponent('locality'),
                street: getComponent('street'),
                house: getComponent('house') ? parseInt(getComponent('house'), 10) : null,
                lng: coords[1],
                lat: coords[0],
            };

            return { coords, name, isHouse, addressObj: addressObj };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    mapCenter: [53.511311, 49.418084],
    mapZoom: 12,
    deliveryPolygons: [],

    searchTerm: '',

    geocodeLoading: null,
    suggestions: [],

    sendGeocodeAddressStatus: null,
    geocodeAddress: null,

    sendGeocodeCoordsStatus: null,
    geocodeCoords: null,

    addressGeocodeObject: null,
    aditionalAddressObject: null,

    isHouse: false,
    isInsidePolygon: false,
};

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setMapCenter: (state, action) => {
            state.mapCenter = action.payload;
        },
        setMapZoom: (state, action) => {
            state.mapZoom = action.payload;
        },
        setDeliveryPolygons: (state, action) => {
            state.deliveryPolygons = action.payload;
        },
        incrementMapZoom: (state) => {
            if (state.mapZoom < 20) {
                state.mapZoom += 1;
            }
        },
        decrementMapZoom: (state) => {
            if (state.mapZoom > 1) {
                state.mapZoom -= 1;
            }
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setGeocodeLoading: (state, action) => {
            state.geocodeLoading = action.payload;
        },
        setSuggestions: (state, action) => {
            state.suggestions = action.payload;
        },
        setGeocodeAddress: (state, action) => {
            state.geocodeAddress = action.payload;
        },
        setGeocodeCoords: (state, action) => {
            state.geocodeCoords = action.payload;
        },
        setAddressGeocodeObject: (state, action) => {
            state.addressGeocodeObject = action.payload;
        },
        setAditionalAddressGeocodeObject: (state, action) => {
            state.aditionalAddressObject = action.payload;
        },
        setIsHouse: (state, action) => {
            state.isHouse = action.payload;
        },
        setIsInsidePolygon: (state, action) => {
            state.isInsidePolygon = action.payload;
        },
        resetMapState: (state) => {
            state.searchTerm = '';
            state.geocodeLoading = null;
            state.suggestions = [];
            state.sendGeocodeAddressStatus = null;
            state.geocodeAddress = null;
            state.sendGeocodeCoordsStatus = null;
            state.geocodeCoords = null;
            state.addressGeocodeObject = null;
            state.isHouse = false;
            state.isInsidePolygon = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // fetchGeocodeAddress
            .addCase(sendGeocodeAddress.pending, (state) => {
                state.sendGeocodeAddressStatus = 'pending';
                state.suggestions = [];
            })
            .addCase(sendGeocodeAddress.fulfilled, (state, action) => {
                state.sendGeocodeAddressStatus = 'fulfilled';
                if (action.payload.length > 1) {
                    state.suggestions = action.payload;
                } else if (action.payload.length === 1) {
                    state.geocodeAddress = action.payload[0].name;
                    state.geocodeCoords = action.payload[0].coords;
                    state.isHouse = action.payload[0].isHouse;
                    state.addressGeocodeObject = action.payload[0].addressObj;
                } else {
                    state.suggestions = [];
                }
                state.geocodeLoading = false;
            })
            .addCase(sendGeocodeAddress.rejected, (state) => {
                state.sendGeocodeAddressStatus = 'rejected';
                state.suggestions = [];
                state.geocodeAddress = null;
                state.geocodeCoords = null;
                state.isHouse = false;
                state.addressGeocodeObject = null;
                state.geocodeLoading = false;
            })

            // fetchGeocodeCoords
            .addCase(sendGeocodeCoords.pending, (state) => {
                state.sendGeocodeCoordsStatus = 'pending';
                state.geocodeAddress = null;
                state.geocodeCoords = null;
            })
            .addCase(sendGeocodeCoords.fulfilled, (state, action) => {
                state.sendGeocodeCoordsStatus = 'fulfilled';
                state.geocodeAddress = action.payload.name;
                state.geocodeCoords = action.payload.coords;
                state.isHouse = action.payload.isHouse;
                state.addressGeocodeObject = action.payload.addressObj;
                state.geocodeLoading = false;
            })
            .addCase(sendGeocodeCoords.rejected, (state) => {
                state.sendGeocodeCoordsStatus = 'rejected';
                state.geocodeAddress = null;
                state.geocodeCoords = null;
                state.isHouse = false;
                state.addressGeocodeObject = null;
                state.geocodeLoading = false;
            });
    },
});

export const {
    setMapCenter,
    setMapZoom,
    setDeliveryPolygons,
    incrementMapZoom,
    decrementMapZoom,
    setSearchTerm,
    setGeocodeLoading,
    setSuggestions,
    setGeocodeAddress,
    setGeocodeCoords,
    setAddressGeocodeObject,
    setAditionalAddressGeocodeObject,
    setIsHouse,
    setIsInsidePolygon,
    resetMapState,
} = mapSlice.actions;

export default mapSlice.reducer;
