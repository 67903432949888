import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';
import { message } from '../../../components/Message';

export const getCheck = createAsyncThunk(
    'check/getCheck',
    async ({ date, owner, company, period, products, promocode = '', paysystem = null, wallet = null }, { rejectWithValue }) => {
        try {
            let data = {
                "date": { "dateString": date },
                "owner": { "id": owner },
                "company": { "id": company },
                "period": { "id": period },
                products,
            };

            if (promocode) {
                data["promocode"] = { "code": promocode };
            }

            if (paysystem) {
                data["paysystem"] = paysystem;
            }

            if (wallet && wallet.id) {
                data["wallet"] = wallet;
            }

            let response = await request("AppDinnerController", "GetCheck", data);

            if (response.result) {
                if (response.data.promocode.result !== undefined) {
                    if (response.data.promocode.result) {
                        console.log('promo_message', response.data.promocode.message);
                    } 
                }
                return response.data;
            } else if (response.errors && Array.isArray(response.errors) && response.errors.length) {
                message({ type: 'default', title: 'Ошибка', body: response.errors[0].description });
                return rejectWithValue(response.errors[0].description);
            } else if (response.message) {
                message({ type: 'default', title: 'Ошибка', body: response.message });
                return rejectWithValue(response.message);
            } else {
                message({ type: 'default', title: 'Ошибка', body: 'Непредвиденная ошибка getCheck' });
                return rejectWithValue('Непредвиденная ошибка getCheck');
            }
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    getCheckStatus: null,
    checkData: null,
    payUrl: null,
};

const cartSlice = createSlice({
    name: 'check',
    initialState,
    extraReducers: (builder) => {
        builder
            // getCheck
            .addCase(getCheck.pending, (state) => {
                state.getCheckStatus = 'pending';
            })
            .addCase(getCheck.fulfilled, (state, action) => {
                state.getCheckStatus = 'fulfilled';
                state.checkData = action.payload;
            })
            .addCase(getCheck.rejected, (state) => {
                state.getCheckStatus = 'rejected';
                state.checkData = null;
            })
    },
    reducers: {
        setPayUrl: (state, action) => { state.payUrl = action.payload; },
    },
});

export const {
    setPayUrl,
} = cartSlice.actions;

export default cartSlice.reducer;