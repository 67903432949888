import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../../components/Button";
import { CartIcon } from "../../../components/Icons";

const CartButton = () => {
	const location = useLocation();
	const { isAuth } = useSelector((state) => state.profile);
	const { totalPrice } = useSelector((state) => state.cart);
	const currentParams = new URLSearchParams(location.search);
	
	return (
		<>
            <DesktopWrapper>
                {isAuth &&
                    <Link to={{ pathname: "/cart", search: currentParams.toString() }}>
                        <Button type='primary'>
                            <CartIcon color='#ffffff'/>
                            <p>{totalPrice > 0 ? `${totalPrice} ₽` : 'Корзина'}</p>
                        </Button>
                    </Link>
                }
            </DesktopWrapper>
            <MobileWrapper>
                {isAuth &&
                    <Link to={{ pathname: "/cart", search: currentParams.toString() }}>
                        <MobileCartButton type='primary'>
                            <CartIcon color='#ffffff'/>
                            {totalPrice > 0 && <TotalPrice>{totalPrice} ₽</TotalPrice>}
                        </MobileCartButton>
                    </Link>
                }
            </MobileWrapper>
		</>
	);
}

export default CartButton;

const DesktopWrapper = styled.div`
	@media (max-width: 798px) {
		display: none;
	}
`;

const MobileWrapper = styled.div`
    display: none;
	@media (max-width: 798px) {
        position: fixed;
        bottom: 32px;
        right: 16px;
		display: block;
        padding: 4px;
        background: #ffffff;
        border-radius: 20px;
	}
`;

const MobileCartButton = styled(Button)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 60px;
    min-height: 60px;
`;

const TotalPrice = styled.div`
    font-size: 10px;
`;
