import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { AddressIcon, CloseIcon } from '../../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { sendGeocodeAddress, setAddressGeocodeObject, setGeocodeAddress, setGeocodeCoords, setIsHouse, setSuggestions } from '../../../../redux/reducer/slices/mapSlice';

const SearchBar = ({ onClick }) => {
    const dispatch = useDispatch();
    const { suggestions, geocodeAddress, addressGeocodeObject } = useSelector((state) => state.map);
    const [value, setValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (geocodeAddress) {
            setValue(
                `${addressGeocodeObject && addressGeocodeObject.locality ? `${addressGeocodeObject.locality}, ` : ''}` +
                `${addressGeocodeObject && addressGeocodeObject.street ? `${addressGeocodeObject.street}, ` : ''}` +
                `${addressGeocodeObject && addressGeocodeObject.house ? `${addressGeocodeObject.house}` : ''}`
            )
        } else {
            setValue('');
        }
    }, [geocodeAddress]);

    const handleInputChange = (e) => {
        setValue(e.target.value);
    }

    const handleSearch = () => {
        dispatch(sendGeocodeAddress(value));
    };

    const handleClear = () => {
        dispatch(setGeocodeAddress(null));
        dispatch(setGeocodeCoords(null));
        dispatch(sendGeocodeAddress(null));
        setValue('');
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    const handleSuggestionClick = (geoAddress, geoCoords, addressGeocodeObject, isHouse) => {
        dispatch(setGeocodeAddress(geoAddress))
        dispatch(setGeocodeCoords(geoCoords))
        dispatch(setAddressGeocodeObject(addressGeocodeObject))
        dispatch(setIsHouse(isHouse));
        dispatch(setSuggestions([]));
        if (inputRef.current) {
            inputRef.current.focus();
            const length = geoAddress.length;
            inputRef.current.selectionStart = length;
            inputRef.current.selectionEnd = length;
        }
    };

    return (
        <SearchWrapper>
            <AddressIcon/>
            <Input
                ref={inputRef}
                type='text'
                placeholder='Введите адрес'
                value={value}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            {value && <ClearButton onClick={handleClear}><CloseIcon width={24} height={24}/></ClearButton>}
            <SearchButton onClick={onClick}>Далее</SearchButton>

            {suggestions && suggestions.length > 1 && (
                <SuggestionsList>
                    {suggestions.map((suggestion, index) => (
                        <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion.name, suggestion.coords, suggestion.addressObj, suggestion.isHouse)}>
                            {suggestion.addressObj.fullName}
                        </SuggestionItem>
                    ))}
                </SuggestionsList>
            )}

        </SearchWrapper>
    );
};

export default SearchBar;

const SearchWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 16px;
    border: 2px solid #E0E0E0;
    border-radius: 20px;
    background-color: #ffffff;
    width: 100%;
    max-width: 900px;
    pointer-events: all;
    @media (max-width: 748px) {
        height: 50px;
        border-radius: 16px;
        border: 2px solid #EAEAEA;
        background-color: #F9F9F9;
    }
`;

const Input = styled.input`
    flex: 1;
    border: none;
    outline: none;
    padding: 0 16px;
    font-size: 16px;
    color: #333;
    background-color: transparent;
    overflow: hidden;
`;

const ClearButton = styled.button`
    height: 20px;
    background: none;
    border: none;
    font-size: 18px;
    line-height: 1;
    color: #999;
    cursor: pointer;
    margin-right: 8px;

    &:hover {
        color: #333;
    }
`;

const SearchButton = styled.button`
    margin-right: -1px;
    padding: 0 16px;
    width: 20%;
    height: 60px;
    background: #EA394B;
    border-radius: 19px;
    border: solid 1px #EA394B;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background: #d73636;
    }
    @media (max-width: 748px) {
        display: none;
    }
`;

const SuggestionsList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-top: none;
    box-shadow: 0px 20px 24px 0px #00000029;
    border-radius: 16px;
    margin-top: 5px;
    padding: 0;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    @media (max-width: 748px) {
        top: unset;
        bottom: 100%;
        margin-bottom: 5px;
        flex-direction: column-reverse;
        box-shadow: 0px 0px 24px 0px #00000029;
    }
`;

const SuggestionItem = styled.li`
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #333;

    &:hover {
        background-color: #f5f5f5;
    }
`;
