import React from "react";
import styled from 'styled-components';
import { CheckIcon } from "./Icons";

const Checkbox = ({ checked = false, onChange }) => {
  return (
    <CheckboxWrapper onClick={onChange}>
      {checked && <CheckIcon color='#EA394B'/>}
    </CheckboxWrapper>
  );
};

export default Checkbox;

const CheckboxWrapper = styled.div`
  width: 30px;
  height: 30px;
  border: solid 1px #ea394b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
`;
