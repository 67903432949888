import axios from 'axios';
import platform from "platform";
import { nanoid } from 'nanoid';
import { sha256 } from './sha256';

export const request = async (service, method, data = {}) => {
  checkConnect();

  	const getCookie = (name) => {
		let value = "; " + document.cookie;
		let parts = value.split("; " + name + "=");
		if (parts.length === 2) return parts.pop().split(";").shift();
	};

let cookieCheck = getCookie("server");
const API_URL = cookieCheck || "https://menuforme.online/api/";

  let localData = {
    token: localStorage.getItem('token') || '',
    session: localStorage.getItem('session') || '',
    loginHash: localStorage.getItem('loginHash') || '',
    codeHash: localStorage.getItem('codeHash') || '',
    deviceId: localStorage.getItem('deviceId') || genDeviceId(),
  };

  let reqData = { service, method, data };

  if (!data.hasOwnProperty('account')) {
    reqData.data.account = 146923;
  }
  reqData.data.holder = { id: 146923 };
  reqData.data.isSigned = true;
  reqData.data.lang = 'ru_RU';
  reqData.data.token = localData.token;
  reqData.data.session = localData.session;
  reqData.data.timeZoneOffset = Date.now() + '.' + getRandomInt(1000, 9999);
  reqData.data.time = sha256(Date.now() + Math.random());
  reqData.data.signature = calcSignature(reqData, localData.loginHash, localData.codeHash);
  reqData.data.clientInfo = JSON.stringify({
    os: platform.os.family,
		osVersion: platform.os.version,
		model: platform.description,
		brand: platform.product,
    screenWidth: window.screen.width,
    deviceId: localData.deviceId,
    appVersion: "1.21.0",
    appId: "com.menu4me.dinner.site.sitiyoffice",
    locale: 'ru_RU',
  });

  try {
    let response = await axios.post(API_URL, JSON.stringify(reqData));
    if (response.data.result === 0) {
      throw {
        service: reqData.service,
        method: reqData.method,
        code: response.data.resultCode,
        message: response.data.data.message,
        result: response.data.result,
        errors: response.data.data.errors,
      };
    }
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

// Интерцептор для обновления токена
axios.interceptors.response.use(async (response) => {
  if (response.data.resultCode === 's152') {
    let refreshResponse = await request('ClientAuthService', 'RefreshToken', {
      refreshToken: localStorage.getItem('refreshToken'),
    });
    localStorage.setItem('token', refreshResponse.data.token);
    localStorage.setItem('refreshToken', refreshResponse.data.refreshToken);
    
    let newData = JSON.parse(response.config.data);
		newData.data.token = refreshResponse.data.token;
		newData.data.signature = calcSignature(
		newData,
		localStorage.getItem('loginHash') || '',
		localStorage.getItem('codeHash') || '',
    );
    
    response.config.data = JSON.stringify(newData);
    return axios(response.config);
  }
  return response;
});

// Функция генерации уникального ID
const genDeviceId = () => {
  let deviceId = nanoid();
  localStorage.setItem('deviceId', deviceId);
  return deviceId;
};

// Генерация случайного числа в диапазоне
const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

// Подсчёт подписи запроса
const calcSignature = (reqData, loginHash, codeHash) => {
  return sha256(
    reqData.service +
	reqData.method +
	reqData.data.time +
	reqData.data.token +
	loginHash +
	reqData.data.session +
	codeHash
  );
};

// Проверка интернет-соединения
const checkConnect = () => {
  if (!navigator.onLine) {
    console.warn('Отсутствует интернет-соединение');
  }
};
