import React from 'react'
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import Button from '../../../components/Button';
import { setIsAuthModalOpen, setIsAuthQuestionModalClose } from '../../../redux/reducer/slices/profileSlice';

const AuthQuestionForm = () => {
  const dispatch = useDispatch();

  return (
    <>
        <Wrapper>
            <ModalText>Для оформления заказа, укажите свой номер телефона</ModalText>
            <ModalTitle>Авторизуемся сейчас?</ModalTitle>
            <ModalAction>
                <Button style={{flex: 1}} onClick={() => dispatch(setIsAuthQuestionModalClose(false))}>Нет</Button>
                <Button style={{flex: 1}} type='primary' onClick={()=> dispatch(setIsAuthModalOpen())}>Да</Button>
            </ModalAction>
        </Wrapper>
    </>
  )
}

export default AuthQuestionForm;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 450px;
`;

const ModalTitle = styled.div`
    margin-top: 16px;
    font-size: 24px;
    font-weight: 700;
`;

const ModalText = styled.div`
    font-size: 18px;
    line-height: 24px;
    color: #464646;
`;

const ModalAction = styled.div`
    margin-top: 24px;
    display: flex;
    gap: 16px;
`;
