import React, { useEffect } from 'react';
import styled from 'styled-components';

const Modal = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null;
    }

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <ModalWrapper onMouseDown={handleBackdropClick}>
            <ModalContainer>
                <CloseButton onClick={onClose}>
                    <svg width="31" height="31" viewBox="0 0 31 31" stroke="white" strokeWidth="3.5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.8104 7.60352L7.60352 22.8104" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.60365 7.60352L22.8105 22.8104" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </CloseButton>
                {children}
            </ModalContainer>
        </ModalWrapper>
    );
};

export default Modal;

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled.div`
    position: relative;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    padding: 32px;
    border-radius: 16px;
    min-width: 320px;
    max-height: 80vh;
    @media (max-width: 798px) {
        padding: 32px 16px 16px;
    }
`;

const CloseButton = styled.span`
    position: absolute;
    top: -5px;
    right: -40px;
    cursor: pointer;
    @media (max-width: 798px) {
        top: 15px;
        right: 10px;
        svg {
            width: 20px;
            height: 20px;
            stroke: #000000;
            stroke-width: 2.5;
        }
    }
`;
