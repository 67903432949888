import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "./Router";
import Layout from "./Layout";
import { getAppSettings, setIsCityModalOpen } from "../redux/reducer/slices/commonSlice";
import { getProfile } from "../redux/reducer/slices/profileSlice";
import { getCompanies } from "../redux/reducer/slices/companiesSlice";
import { getOrders } from "../redux/reducer/slices/ordersSlice";
import { getDataForMainPage, getKitchens } from "../redux/reducer/slices/kitchensSlice";
import useDeviceInfo from "../hooks/useDeviceInfo";

const App = () => {
    useDeviceInfo();
    const dispatch = useDispatch();
    const { device, orientation, currentCity } = useSelector((state) => state.common);
    const { isAuth } = useSelector((state) => state.profile);

    console.log('device:', device, 'orientation:', orientation, 'isAuth:', isAuth);

    useEffect(() => {
        dispatch(getAppSettings());
        dispatch(getDataForMainPage());

        if (!currentCity) {
            dispatch(setIsCityModalOpen());
        }

        if (isAuth) {
            dispatch(getProfile());
            dispatch(getCompanies());
            dispatch(getOrders());
        }
    }, [isAuth]);

    useEffect(() => {
        dispatch(getKitchens());
    }, [currentCity]);

    return (
        <Layout>
			<Router/>
        </Layout>
    );
};

export default App;
