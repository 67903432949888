import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BurgerIcon, LogoIcon } from '../../components/Icons';
// import InputWithButton from "../../components/InputWithButton";
import AddressButton from "./components/AddressButton";
import Button from "../../components/Button";
import FloatingSocialButtons from "../../components/FloatingSocialButtons";
import ProfileButton from "./components/ProfileButton";
import CartButton from "./components/CartButton";
import MobileSidebar from "./components/MobileSidebar";

const Header = () => {
	const location = useLocation();
	const currentParams = new URLSearchParams(location.search);
	const { isAuth } = useSelector((state) => state.profile);
	const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

	// const handleSearch = () => {
	// 	alert('Нужна функция для серверного поиска по кухням')
	// }
	
	const handleMobileSidebarOpen = () => { setIsMobileSidebarOpen(true); }
	const handleMobileSidebarClose = () => { setIsMobileSidebarOpen(false); }

	return (
		<>
			<DesktopWrapper>
				<LeftCol>
					<LogoWrapper>
						<Link to={{ pathname: "/", search: currentParams.toString() }}>
							<LogoIcon/>
						</Link>
					</LogoWrapper>

					{/* <InputWithButton
						icon={SearchIcon}
						placeholder="Поиск блюд и кухонь"
						buttonText="Найти"
						onClick={handleSearch}
					/> */}

					<AddressButton/>
				</LeftCol>

				<RightCol>
					<CartButton/>
					<ProfileButton/>
				</RightCol>
			</DesktopWrapper>

			<MobileWrapper>
				<Button style={{flex: 0}} size='small' type='icon' onClick={handleMobileSidebarOpen}><BurgerIcon/></Button>
				{isAuth ? <AddressButton/> : <ProfileButton/>}
				<FloatingSocialButtons/>

				<CartButton/>

				<MobileSidebar isOpen={isMobileSidebarOpen} onClose={handleMobileSidebarClose}/>
			</MobileWrapper>
		</>
	);
}

export default Header;

const DesktopWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 900;
	display: flex;
	justify-content: space-between;
	gap: 60px;
	padding: 0 40px;
	background-color: #ffffff;
	height: 100px;
	@media (max-width: 798px) {
		display: none;
	}
`;

const LeftCol = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	gap: 20px;
`;

const LogoWrapper = styled.div`
	width: fit-content;
	@media (max-width: 1024px) {
		display: none;
	}
`;

const RightCol = styled.div`
	flex: 0;
	display: flex;
	align-items: center;
	gap: 20px;
	@media (max-width: 798px) {
		gap: 10px;
	}
`;

const MobileWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 900;
	display: none;
	justify-content: space-between;
	gap: 8px;
	padding: 24px 16px 12px;
	background-color: #ffffff;
	height: 80px;
	@media (max-width: 798px) {
		display: flex;
	}
`;

const MobileMenuWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 900;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	background-color: #f8f8f8;
`;

const MenuHeader = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
	padding: 24px 16px 12px;
	background-color: #ffffff;
`;

const MenuProfileInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	p {
		color: #969696;
	}
`;

const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 380px;
`;

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
	height: 50px;
    background-color: #FFFFFF;
    border-radius: 16px;
	box-shadow: 0px 8px 25px 0px #C1C1C140;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: #e7e7e7;
    }
`;

const Text = styled.p`
    width: 100%;
`;