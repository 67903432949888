import React from 'react';
import styled from 'styled-components';

const Flex = ({
  children,
  className,
  flex,
  column,
  align,
  justify,
  wrap,
  gap,
  ...props
}) => {
  return (
    <StyledFlex
      className={className}
      flex={flex}
      column={column}
      align={align}
      justify={justify}
      wrap={wrap}
      gap={gap}
      {...props}
    >
      {children}
    </StyledFlex>
  );
};

export default Flex;

const StyledFlex = styled.div`
  display: flex;
  flex: ${(props) => props.flex || 'initial'};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  align-items: ${(props) => props.align || 'stretch'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  gap: ${(props) => props.gap || '0'};
  max-width: 100%;
  min-width: 0;
`;