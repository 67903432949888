import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';

export const getCutleryForCart = createAsyncThunk(
    'cart/getCutleryForCart',
    async (data, { rejectWithValue }) => {
        try {
            const response = await request('AppDinnerController', 'GetCart', data);

            let sectionsArr = [];
            if (response.result) {
                if (response?.data?.menu?.elements && response?.data?.menu?.sections) {
                    let sections = Object.values(response.data.menu.sections);
                    let elements = Object.values(response.data.menu.elements);

                    response.data.menu.sectionsAsArray.forEach(sectionId => {
                        let foundSection = sections.find(i => i.id === sectionId);
                        if (foundSection) {
                            let elementsArr = [];
                            foundSection.elementsAsArray.forEach(elementId => {
                                let foundElement = elements.find(i => i.id === elementId);
                                if (foundElement) {
                                    elementsArr.push(foundElement);
                                }
                            });
                            foundSection.elements = elementsArr;
                            delete foundSection.elementsAsArray;
                            sectionsArr.push(foundSection);
                        }
                    });

                    return sectionsArr;
                }
            } else {
                return rejectWithValue(response?.message);
            }
        } catch (err) {
            console.log('Ошибка запроса AppDinnerController/GetCart: ' + err);
            return rejectWithValue(err.message);
        }
    }
);

const initialState = {
    isShowClearCartModal: false,
    products: [],
    ordersCount: 0,
    totalPrice: 0,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        isShowClearCartModalOpen: (state) => { state.isShowClearCartModal = true; },
        isShowClearCartModalClose: (state) => { state.isShowClearCartModal = false; },
        addProductToCart: (state, action) => {
            const { product, amount } = action.payload;
            const existingProduct = state.products.find(item => item.uniqId === product.uniqId);
            const existingKitchenOrder = state.products.find(item => item.kitchenId === product.kitchenId);
            const otherDate = state.products.some(item => item.date !== product.date);

            if (existingProduct && !otherDate) {
                existingProduct.amount += amount ? amount : 1;
            } else {
                if (otherDate) {
                    state.isShowClearCartModal = true;
                } else {
                    if (!existingKitchenOrder) {
                        state.ordersCount += 1;
                    }

                    state.products.push({
                        ...product,
                        amount: amount ? amount : 1,
                        order: existingKitchenOrder ? existingKitchenOrder.order : state.ordersCount,
                    });
                }
            }
        
            state.totalPrice = state.products.reduce((acc, item) => {
                const basePrice = +item.price;
                const modifiersPrice = item.modifiers?.reduce((modAcc, mod) => modAcc + (+mod.price || 0), 0) || 0;
                const totalItemPrice = (basePrice + modifiersPrice) * item.amount;
                return acc + totalItemPrice;
            }, 0);
        },
        removeProductFromCart: (state, action) => {
            const { uniqId, removeAll } = action.payload;
            const existingProduct = state.products.find(item => item.uniqId === uniqId);
        
            if (existingProduct) {
                if (removeAll) {
                    state.products = state.products.filter(item => !(item.uniqId === uniqId));
                } else {
                    existingProduct.amount -= 1;
                }
            }
        
            if (state.products.length === 0) {
                state.ordersCount = 0;
            }

            state.totalPrice = state.products.reduce((acc, item) => {
                const basePrice = +item.price;
                const modifiersPrice = item.modifiers?.reduce((modAcc, mod) => modAcc + (+mod.price || 0), 0) || 0;
                const totalItemPrice = (basePrice + modifiersPrice) * item.amount;
                return acc + totalItemPrice;
            }, 0);
        },
        removeOrder: (state, action) => {
            const orderToRemove = String(action.payload);
      
            state.products = state.products.filter(product => String(product.order) !== orderToRemove);
            state.totalPrice = state.products.reduce((acc, item) => {
                const basePrice = +item.price;
                const modifiersPrice = item.modifiers?.reduce((modAcc, mod) => modAcc + (+mod.price || 0), 0) || 0;
                const totalItemPrice = (basePrice + modifiersPrice) * item.amount;
                return acc + totalItemPrice;
            }, 0);
      
            if (state.products.length === 0) {
                state.ordersCount = 0;
            }
        },
        clearCart: (state) => {
            state.isShowClearCartModal = false;
            state.products = [];
            state.ordersCount = 0;
            state.totalPrice = 0;
        },
    },
});

export const {
    isShowClearCartModalOpen,
    isShowClearCartModalClose,
    addProductToCart,
    removeProductFromCart,
    removeOrder,
    clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;