import React, { useRef, useState } from 'react';
import styled from 'styled-components';

let addContent;

export const DraggableBox = () => {
    const boxRef = useRef(null);
    const headerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [content, setContent] = useState(null);

    const handleMouseDown = (e) => {
        if (headerRef.current && headerRef.current.contains(e.target)) {
            setIsDragging(true);
            setOffset({
                x: e.clientX - boxRef.current.getBoundingClientRect().left,
                y: e.clientY - boxRef.current.getBoundingClientRect().top,
            });
            boxRef.current.style.cursor = 'grabbing';
            boxRef.current.style.userSelect = 'none';
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        boxRef.current.style.cursor = 'grab';
        boxRef.current.style.userSelect = 'auto';
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                top: e.clientY - offset.y,
                left: e.clientX - offset.x,
            });
        }
    };

    const renderJson = (data) => {
        if (typeof data === 'object' && data !== null) {
            return (
                <ul style={{width: 'fit-content'}}>
                    {Object.keys(data).map((key) => (
                        <li style={{paddingLeft: '20px', textAlign: 'left'}} key={key}>
                            <span style={{color: '#cccccc'}}>{key}: <strong style={{color: 'green'}}>{renderJson(data[key])}</strong></span>
                        </li>
                    ))}
                </ul>
            );
        } else {
            return <span>{String(data)}</span>;
        }
    };

    addContent = (content) => {
        setContent(content);
    };

    return (
        <Box
            ref={boxRef}
            style={{ top: `${position.top}px`, left: `${position.left}px` }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
        >
            <Header ref={headerRef}>Отладка</Header>
            <Content>
                <ServerInput/>
                {content && renderJson(content)}
            </Content>
        </Box>
    );
};

export const testLogs = (content) => {
    if (addContent) {
        addContent(content);
    }
};

const ServerInput = () => {
    const [server, setServer] = useState('');

    const getCookie = (name) => {
		let value = "; " + document.cookie;
		let parts = value.split("; " + name + "=");
		if (parts.length === 2) return parts.pop().split(";").shift();
	};

    const setCookie = (name, value, days) => {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    let cookieCheck = getCookie("server");
    const currentServer = cookieCheck || "https://menuforme.online/api/";

    const handleInputChange = (e) => {
        setServer(e.target.value);
    };

    const handleConfirm = () => {
        setCookie('server', server, 1);
        window.location.reload();
    };
    
    const handleCancel = () => {
        setCookie('server', 'https://menuforme.online/api/', 1);
        window.location.reload();
    };

    return (
        <ServerInputComponent>
            <Input
                type="text"
                value={server}
                onChange={handleInputChange}
                placeholder={currentServer}
            />
            <ButtonContainer>
                <Button primary onClick={handleConfirm}>Применить</Button>
                <Button onClick={handleCancel}>Отменить</Button>
            </ButtonContainer>
        </ServerInputComponent>
    );
};

const Box = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    width: 320px;
    height: auto;
    background-color: #252525;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: grab;
`;

const Header = styled.div`
    background-color: #353535;
    color: #ffffff;
    padding: 8px;
    cursor: grab;
`;

const Content = styled.div`
    padding: 16px;
    max-height: 50vh;
    overflow-y: auto;
    font-size: 14px;
    color: #ffffff;
`;

const ServerInputComponent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Input = styled.input`
    width: 100%;
    border: solid 1px #cccccc;
    border-radius: 4px;
    padding: 4px;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
`;

const Button = styled.button`
    width: 50%;
    background: ${({ primary }) => (primary ? '#353535' : '#757575')};
    border-radius: 4px;
    padding: 4px;
    color: #ffffff;
`;