import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCity, setIsCityModalClose } from '../../../redux/reducer/slices/commonSlice';
import { clearCart } from "../../../redux/reducer/slices/cartSlice";
import styled from "styled-components";
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Title from '../../../components/Title';

const SelectCityForm = () => {
    const { deliveryZones } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    const handleCityClick = (city) => {
        localStorage.setItem('currentCity', JSON.stringify(city));
        dispatch(setCurrentCity(city));
        dispatch(clearCart());
        dispatch(setIsCityModalClose());
    }

    return (
        <Wrapper>
            <Title level={2}>В каком вы городе?</Title>
            <Form>
                {deliveryZones && deliveryZones.length > 0 ?
                    deliveryZones.map(city => (
                        <Button key={city.id} onClick={() => handleCityClick({id: city.id, name: city.name, active: null})}>{city.name}</Button>
                    )) 
                : 
                    <Loader heightContainer={500}/>
                }
            </Form>
        </Wrapper>
    )
}

export default SelectCityForm

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    padding-right: 5px;
    width: 100%;
    max-height: calc(80vh - 120px);
    overflow: auto;
`;
