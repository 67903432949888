import React, { useState } from 'react';
import styled from 'styled-components';
import { SupportIcon, TGIcon, VBIcon, VKIcon, WSIcon } from './Icons';

const FloatingSocialButtons = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleButtons = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper isOpen={isOpen}>
      <MainButton onClick={toggleButtons}>
        <SupportIcon/>
      </MainButton>

      <IconButton href='https://vk.com/sitiy_ru' target="_blank" rel="noopener noreferrer" bgColor="#0079FF" isOpen={isOpen}>
        <VKIcon/>
      </IconButton>
      <IconButton href='https://telegram.me/sitiy_support_bot' target="_blank" rel="noopener noreferrer" bgColor="#44B3E3" isOpen={isOpen}>
        <TGIcon/>
      </IconButton>
      <IconButton href='viber://pa?chatURI=sitiy' target="_blank" rel="noopener noreferrer" bgColor="#9156B4" isOpen={isOpen}>
        <VBIcon/>
      </IconButton>
      <IconButton href='https://wa.me/79914590879' target="_blank" rel="noopener noreferrer" bgColor="#69C060" isOpen={isOpen}>
        <WSIcon/>
      </IconButton>
    </Wrapper>
  );
};

export default FloatingSocialButtons;

const Wrapper = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: -4px;
  padding: 4px;
  gap: 4px;
  width: ${(props) => (props.isOpen ? '324px' : '68px')};
  z-index: 100;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  transition: width 0.3s ease;
  @media (max-width: 798px) {
    position: relative;
    bottom: auto;
    right: auto;
    flex-direction: column;
    min-width: 53px;
    width: 53px;
    height: ${(props) => (props.isOpen ? '250px' : '53px')};
  }
`
const Button = styled.a`
  position: relative;
  min-width: 60px;
  min-height: 60px;
  border-radius: 18px;
  background-color: ${(props) => props.bgColor || '#EA394B'};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: #ffffff !important;
  text-decoration: none;
  transition: transform 0.3s ease, opacity 0.3s ease;
  &:hover {
    transform: scale(1.05);
    color: #ffffff;
    text-decoration: none;
  }
  @media (max-width: 798px) {
    min-width: 45px;
    min-height: 45px;
  }
`
const MainButton = styled(Button)`
  z-index: 2;
  @media (max-width: 798px) {
    background-color: #f1f1f1;
    svg {
      color: #2c2e32;
    }
  }
`
const IconButton = styled(Button)`
  z-index: 1;
  transform: ${(props) => (props.isOpen ? 'translateX(0)' : 'translateX(100px)')};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  @media (max-width: 798px) {
    transform: ${(props) => (props.isOpen ? 'translateY(0)' : 'translateY(-100px)')};
  }
`
