import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthModalOpen } from "../../../redux/reducer/slices/profileSlice";
import styled from "styled-components";
import Button from "../../../components/Button";
import { ProfileIcon } from "../../../components/Icons";

const ProfileButton = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { isAuthModalOpen, isAuth, profile } = useSelector((state) => state.profile);
	const currentParams = new URLSearchParams(location.search);

	const handleClickAuth = () => {
		console.log(isAuthModalOpen, 'isAuthModalOpen');
		dispatch(setIsAuthModalOpen());
	}
	return (
		<>
            <DesktopWrapper>
                {isAuth ? (
                    <Link to={{ pathname: "/profile", search: currentParams.toString() }}>
                        <Button>
                            <ProfileIcon/>
                            <p>{profile && profile.client && profile.client.name
                                ? profile.client.name
                                : profile && profile.client && profile.client.phone
                                ? profile.client.phone
                                : 'Профиль'
                            }</p>
                        </Button>
                    </Link>
                ) : (
                    <Button type='primary' onClick={handleClickAuth}>
                        <ProfileIcon color='#ffffff'/>
                        <p>Войти</p>
                    </Button>
                )}
            </DesktopWrapper>
            <MobileWrapper>
                {!isAuth && <Button size='small' type='primary' onClick={handleClickAuth}>Авторизоваться</Button>}
            </MobileWrapper>
		</>
	);
}

export default ProfileButton;

const DesktopWrapper = styled.div`
	@media (max-width: 798px) {
		display: none;
	}
`;

const MobileWrapper = styled.div`
    display: none;
	@media (max-width: 798px) {
		display: block;
	}
`;
