import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MainPage = React.lazy(() => import('../containers/mainPage/MainPage'));
const MenuPage = React.lazy(() => import('../containers/menuPage/MenuPage'));
const CartPage = React.lazy(() => import('../containers/cartPage/CartPage'));
const CheckPage = React.lazy(() => import('../containers/checkPage/CheckPage'));
const ProfilePage = React.lazy(() => import('../containers/profilePage/ProfilePage'));
// const AboutPage = React.lazy(() => import('../containers/aboutPage/AboutPage'));
// const PaymentInfoPage = React.lazy(() => import('../containers/paymentInfoPage/PaymentInfoPage'));
// const DeliveryInfoPage = React.lazy(() => import('../containers/deliveryInfoPage/DeliveryInfoPage'));
// const PartnersPage = React.lazy(() => import('../containers/partnersPage/PartnersPage'));
const InteractiveMapPromoModule = React.lazy(() => import('../interactiveMapPromoModule/InteractiveMapPromoModule'));
const NotFoundPage = React.lazy(() => import('../containers/notFoundPage/NotFoundPage'));

const Router = () => {
    const { isAuth } = useSelector(state => state.profile);

    return (
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<MainPage/>}/>
                <Route path="/menu/:id" element={<MenuPage/>}/>
                <Route path="/cart" element={isAuth ? <CartPage/> : <Navigate to="/"/>}/>
                <Route path="/check" element={isAuth ? <CheckPage/> : <Navigate to="/"/>}/>
                <Route path="/profile" element={isAuth ? <ProfilePage/> : <Navigate to="/"/>}/>
                <Route path="/profile/:tab" element={isAuth ? <ProfilePage/> : <Navigate to="/"/>}/>
                {/* <Route path="/about" element={<AboutPage/>}/>
                <Route path="/payment" element={<PaymentInfoPage/>}/>
                <Route path="/delivery" element={<DeliveryInfoPage/>}/> */}
                {/* <Route path="/partners" element={<PartnersPage/>}/> */}
                <Route path="/app" element={<InteractiveMapPromoModule/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
        </Suspense>
    );
};

export default Router;
