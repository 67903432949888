import { combineReducers } from '@reduxjs/toolkit';
import commonSlice from './slices/commonSlice';
import profileSlice from './slices/profileSlice';
import companiesSlice from './slices/companiesSlice';
import mapSlice from './slices/mapSlice';
import kitchensSlice from './slices/kitchensSlice';
import cartSlice from './slices/cartSlice';
import ordersSlice from './slices/ordersSlice';
import checkSlice from './slices/checkSlice';
import interactiveMapPromoReducer from '../../interactiveMapPromoModule/features/interactiveMapPromoReducer';

const combineReducer = combineReducers({
    common: commonSlice,
    profile: profileSlice,
    companies: companiesSlice,
    map: mapSlice,
    kitchens: kitchensSlice,
    cart: cartSlice,
    orders: ordersSlice,
    check: checkSlice,
    interactiveMapPromo: interactiveMapPromoReducer,
});

export default combineReducer;
