import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';
import { setCurrentCity } from './commonSlice';
import { message } from '../../../components/Message';

// Получение всех адресов (компаний) пользователя
export const getCompanies = createAsyncThunk(
    'companies/getCompanies',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request("AppDinnerController", "GetCompanies");

            if (response.result) {
                const activeCompany = response.data && response.data.companies
                    ? response.data.companies.find(item => item.type === "1")
                    : null;

                return {
                    companies: response.data.companies,
                    activeCompany: activeCompany,
                };
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.log(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

// Добавление нового адреса (компании) пользователя
export const saveCompany = createAsyncThunk(
    'companies/saveCompany',
    async (dataCompany, { dispatch, rejectWithValue }) => {
        try {
            const response = await request("AppDinnerController", "SaveCompany", { company: { ...dataCompany } });
            console.log(response);

            if (response.result) {
                dispatch(getCompanies());

                return response.data;
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.log(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

// Изменение текущего адреса (компании) пользователя
export const changeActiveCompany = createAsyncThunk(
    'companies/changeActiveCompany',
    async (company, { dispatch, rejectWithValue }) => {
        console.log(company, 'company changeActiveCompany');
        try {
            const response = await request("AppDinnerController", "SetUserCurrentCompany", { companyId: company.id });

            if (response.result) {
                const cityObj = company.address.city;
                localStorage.setItem('currentCity', JSON.stringify({id: cityObj.id, name: cityObj.name, active: null}));
                dispatch(setCurrentCity({id: cityObj.id, name: cityObj.name, active: null}));

                return company;
            } else {
                return rejectWithValue('Ошибка изменения текущей компании');
            }
        } catch (error) {
            console.log(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

// Удаление адреса (компании) пользователя
export const removeCompany = createAsyncThunk(
    'companies/removeCompany',
    async (data, { dispatch, rejectWithValue }) => {

        try {
            const response = await request("AppDinnerController", "RemoveUserFromCompany", data);

            if (response.result) {
                message({type: 'default', title: 'Адрес', body: 'Адрес успешно удален'});
                dispatch(getCompanies());

                return response.data;
            } else {
                return rejectWithValue('Ошибка удаления компании');
            }
        } catch (error) {
            console.log(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

const initialState = {
    getCompaniesStatus: null,
    companies: [],
    activeCompany: null,

    isAddressModalOpen: false,

    saveCompanyStatus: null,
    changeActiveCompanyStatus: null,
    removeCompanyStatus: null,
};

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    extraReducers: (builder) => {
        builder
            // getCompanies
            .addCase(getCompanies.pending, (state) => {
                state.getCompaniesStatus = 'pending';
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.getCompaniesStatus = 'fulfilled';
                state.companies = action.payload.companies;
                state.activeCompany = action.payload.activeCompany;
            })
            .addCase(getCompanies.rejected, (state) => {
                state.getCompaniesStatus = 'rejected';
            })

            // saveCompany
            .addCase(saveCompany.pending, (state) => {
                state.saveCompanyStatus = 'pending';
            })
            .addCase(saveCompany.fulfilled, (state) => {
                state.saveCompanyStatus = 'fulfilled';
            })
            .addCase(saveCompany.rejected, (state) => {
                state.saveCompanyStatus = 'rejected';
            })

            // changeActiveCompany
            .addCase(changeActiveCompany.pending, (state) => {
                state.changeActiveCompanyStatus = 'pending';
            })
            .addCase(changeActiveCompany.fulfilled, (state, action) => {
                state.changeActiveCompanyStatus = 'fulfilled';
                const company = action.payload;
                state.companies = state.companies.map(item => item.id === company.id ? {...item, type: "1"} : {...item, type: "2"});
                state.activeCompany = {...company, type: "1"};
            })
            .addCase(changeActiveCompany.rejected, (state) => {
                state.changeActiveCompanyStatus = 'rejected';
            })

            // removeCompany
            .addCase(removeCompany.pending, (state) => {
                state.removeCompanyStatus = 'pending';
            })
            .addCase(removeCompany.fulfilled, (state) => {
                state.removeCompanyStatus = 'fulfilled';
            })
            .addCase(removeCompany.rejected, (state) => {
                state.removeCompanyStatus = 'rejected';
            })
    },
    reducers: {
        setIsAddressModalOpen: (state) => {
            state.isAddressModalOpen = true;
        },
        setIsAddressModalClose: (state) => {
            state.isAddressModalOpen = false;
        },
    },
});

export const {
    setIsAddressModalOpen,
    setIsAddressModalClose,
} = companiesSlice.actions;

export default companiesSlice.reducer;
